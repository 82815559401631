import React from 'react';
import {close_auth_modal, open_auth_modal, open_citys_popup} from "../../redux/auth-reducer";
import {connect} from "react-redux";
import Header from './Header';

let mapStateToProps = (state) => {
    return {
        auth_modal_show: state.authPage.auth_modal_show,
        isAuth: state.authPage.isAuth,
        lotteryAllListWinners: state.authPage.lotteryAllListWinners,
        lotteryRandom: state.authPage.lotteryRandom,
        city_name: state.authPage.citys[state.authPage.currentCity]['name'],
        city_phone: state.authPage.citys[state.authPage.currentCity]['phone'],
        city_phone_call: state.authPage.citys[state.authPage.currentCity]['phone_call'],
        city_url: state.authPage.citys[state.authPage.currentCity]['url'],
        timefrom: state.authPage.citys[state.authPage.currentCity]['timefrom'],
        timeto: state.authPage.citys[state.authPage.currentCity]['timeto'],
        orderResolution: state.authPage.orderResolution,
        orderIsWaitPaid: state.authPage.orderIsWaitPaid,
        linkForOnlinePayment: state.authPage.linkForOnlinePayment,
        orderStatus: state.authPage.orderStatus,
        orderAddress: state.authPage.orderAddress,
        lotteryList: state.authPage.lotteryList,
        orderCountSets: state.authPage.orderCountSets,
        orderSum: state.authPage.orderSum,
        orderNumberQueue: state.authPage.orderNumberQueue,
        orderIsClose: state.authPage.orderIsClose,
        orderIsReady: state.authPage.orderIsReady,
        orderNumber: state.authPage.orderNumber,
        citys_count: Object.keys(state.authPage.citys).length,
        iframe: state.authPage.iframe
    }
    
}
const HeaderContainer = connect(mapStateToProps, {close_auth_modal,open_auth_modal, open_citys_popup})(Header);

export default HeaderContainer;