import './main.scss';
import React, {useEffect} from 'react';
import HeaderContainer from './components/Header/HeaderContainer';
import Algoritm from './components/Algoritm/Algoritm';
import WinnersContainer from './components/Winners/WinnersContainer';
import RandomContainer from './components/Random/RandomContainer';
import Partners from './components/Partners/Partners';
import Prize from './components/Prize/Prize';
import Questions from './components/Questions/Questions';
import Footer from './components/Footer/Footer';
import Auth_modalContainer from './components/Auth_modal/Auth_modalContainer';
import VideoMarafon from './components/VideoMarafon/VideoMarafon';
import Bonuses from './components/Bonuses/Bonuses';
import BannerContainer from "./components/Banner/BannerContainer";
import TitleContainer from "./components/Title/TitleContainer";
import {useDispatch, useSelector} from "react-redux";
import {
    auth_client,
    change_city,
    change_password_flag,
    change_password_timer,
    change_sets_count,
    forceUpdateAccessToken,
    isValidToken,
    refreshTokenF,
    setIframe,
    sets_checker
} from "./redux/auth-reducer";
import Order_modal from "./components/Order_modal/Order_modal";
import Citys_modal from "./components/Citys_modal/Citys_modal";
import Timer from "./components/Timer/Timer";
import {useRouteMatch} from "react-router-dom";
import Static_modals from "./components/Static_modals/Static_modals";
import {sendSign} from "./api";
import { useHistory, useLocation } from 'react-router-dom'


function App() {
    let dispatch = useDispatch();

    const location = useLocation()
    const history = useHistory()

    const queryParameters = new URLSearchParams(location.search)

    if (null !== queryParameters.get("payload")) {
        const payload = JSON.parse(queryParameters.get("payload"));

        const name = payload.user ? payload.user.first_name : null;
        const photo = payload.user ? payload.user.avatar : null;

        const uuid = payload.uuid ?? '';
        const token = payload.token ?? '';

        sendSign(token, uuid, name, photo).then(response => {
            if (true === response.result) {
                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('refreshToken', response.refreshToken);

                dispatch(auth_client(response.accessToken, response.refreshToken))
            }
        });

        history.replace({
            search: '',
        })
    }


    let city_url = useRouteMatch('/:city_url');
    let citys = useSelector(state => state.authPage.citys);
    let isAuth = useSelector(state => state.authPage.isAuth);
    let currentCity = useSelector(state => state.authPage.currentCity);
    let orderResolution = useSelector(state => state.authPage.orderResolution);

    useEffect(() => {

        let params = (new URL(document.location)).searchParams; 
        let date_cookie = new Date;
        date_cookie.setDate(date_cookie.getDate() + 5);
        // if(params.get("referral_link") !== null) document.cookie = "referral_link="+params.get("referral_link")+"; path=/; expires=" + date_cookie.toUTCString();
        if(city_url){
            for(let key in citys){
                if(citys[key]['url'] == city_url['params']['city_url']){dispatch(change_city(key));}
            }
        }

        if (params.get("iframe")) {
            const iframe = JSON.parse(params.get("iframe"));

            if (iframe) {
                dispatch(setIframe(iframe));
            }

            if (params.get("accessToken") && params.get("refreshToken")) {
                const accessToken = params.get("accessToken");
                const refreshToken = params.get("refreshToken");

                if (isValidToken(accessToken)) {
                    dispatch(auth_client(accessToken, refreshToken));
                    dispatch(forceUpdateAccessToken());
                }
            }
        }

    },[]);//Выставление города при заходе с урл

    return (
        <>
            <Timer city_id={currentCity} isAuth={isAuth}/>
            <Auth_modalContainer/>
            <Order_modal />
            <Citys_modal />
            <Static_modals />
            <HeaderContainer/>

            {
                orderResolution
                    ?
                        <>
                            <TitleContainer/>
                            <Algoritm/>
                            <BannerContainer/>
                        </>

                    :
                        <>  
                            <TitleContainer/>
                            <BannerContainer/>
                            <Algoritm/>
                        </>
            }
            
            <Questions/>
            <Footer/>
        </>
    )
}

export default App;
