import Hochland from '../images/partners/hochland.png'
import Vostok_zapad from '../images/partners/vostok_zapad.png'
import Device from '../images/partners/Device.png'
import Tamaki from '../images/partners/tamaki.svg'
import $api from "../http/index";
import Fingerprint2 from 'fingerprintjs2sync'
import {PAYMENT_TYPE_ONLINE, PAYMENT_TYPE_SBP} from "../Constants";

const IS_LOADING = 'IS_LOADING';
const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
const CHANGE_AUTH_MODAL_PHONE = 'CHANGE_AUTH_MODAL_PHONE';
const CHANGE_AUTH_MODAL_PASSWORD = 'CHANGE_AUTH_MODAL_PASSWORD';
const CHANGE_ISAUTH = 'CHANGE_ISAUTH';
const CHANGE_SETSCOUNT = 'CHANGE_SETSCOUNT';
const CHANGE_SETSCOUNTNEW = 'CHANGE_SETSCOUNTNEW';
const INCREASE_SETS_IN_CART = 'INCREASE_SETS_IN_CART';
const DECREASE_SETS_IN_CART = 'DECREASE_SETS_IN_CART';
const INCREASE_DOPS_IN_CART = 'INCREASE_DOPS_IN_CART';
const DECREASE_DOPS_IN_CART = 'DECREASE_DOPS_IN_CART';
const CLOSE_ORDER_MODAL = 'CLOSE_ORDER_MODAL';
const OPEN_ORDER_MODAL = 'OPEN_ORDER_MODAL';
const SET_CURRENT_RESTAURANT = 'SET_CURRENT_RESTAURANT';
const CHANGE_ERROR_LOGIN_TEXT = 'CHANGE_ERROR_LOGIN_TEXT';
const CHANGE_PASSWORD_TIMER = 'CHANGE_PASSWORD_TIMER';
const CHANGE_SEND_PASSWORD_FLAG = 'CHANGE_SEND_PASSWORD_FLAG';
const OPEN_CITYS_POPUP = 'OPEN_CITYS_POPUP';
const CLOSE_CITYS_POPUP = 'CLOSE_CITYS_POPUP';
const CHANGE_CITY = 'CHANGE_CITY';
const SETS_CHECKER = 'SETS_CHECKER';
const CHANGE_REMAINING_SETS_IN_CITY = 'CHANGE_REMAINING_SETS_IN_CITY';
const SET_RESTAURANTS = 'SET_RESTAURANTS';
const CHANGE_TIMER_TO_START = 'CHANGE_TIMER_TO_START';
const NEXT_PAGE = 'NEXT_PAGE';

const CLOSE_ROLL_FIRST = 'CLOSE_ROLL_FIRST';
const OPEN_ROLL_FIRST = 'OPEN_ROLL_FIRST';
const CLOSE_ROLL_SECOND = 'CLOSE_ROLL_SECOND';
const OPEN_ROLL_SECOND = 'OPEN_ROLL_SECOND';
const CLOSE_ROLL_THIRD = 'CLOSE_ROLL_THIRD';
const OPEN_ROLL_THIRD = 'OPEN_ROLL_THIRD';
const CLOSE_ROLL_FOURTH = 'CLOSE_ROLL_FOURTH';
const OPEN_ROLL_FOURTH = 'OPEN_ROLL_FOURTH';

const CLOSE_SOEV_SOUS = 'CLOSE_SOEV_SOUS';
const OPEN_SOEV_SOUS = 'OPEN_SOEV_SOUS';
const CLOSE_IMBIR = 'CLOSE_IMBIR';
const OPEN_IMBIR = 'OPEN_IMBIR';
const CLOSE_VASABI = 'CLOSE_VASABI';
const OPEN_VASABI = 'OPEN_VASABI';

// const CLOSE_SPAIS = 'CLOSE_SPAIS';
// const OPEN_SPAIS = 'OPEN_SPAIS';

// const CLOSE_UNAGI = 'CLOSE_UNAGI';
// const OPEN_UNAGI = 'OPEN_UNAGI';

// const CLOSE_OREH = 'CLOSE_OREH';
// const OPEN_OREH = 'OPEN_OREH';

// const CLOSE_CEZAR = 'CLOSE_CEZAR';
// const OPEN_CEZAR = 'OPEN_CEZAR';

// const CLOSE_FIRM = 'CLOSE_FIRM';
// const OPEN_FIRM = 'OPEN_FIRM';

// const CLOSE_SOUL = 'CLOSE_SOUL';
// const OPEN_SOUL = 'OPEN_SOUL';

const CLOSE_PAL = 'CLOSE_PAL';
const OPEN_PAL = 'OPEN_PAL';

// const CLOSE_REFERRAL = 'CLOSE_REFERRAL';
// const OPEN_REFERRAL = 'OPEN_REFERRAL';

const CHANGE_orderIsWaitPaid = 'CHANGE_orderIsWaitPaid'
const CHANGE_orderIsReady = 'CHANGE_orderIsReady'
const CHANGE_orderIsClose = 'CHANGE_orderIsClose'
const CHANGE_orderResolution = 'CHANGE_orderResolution'
const CHANGE_linkForOnlinePayment = 'CHANGE_linkForOnlinePayment'
const API_ORDERS = 'API_ORDERS'
const LOGOUT = 'LOGOUT';
const SET_DEVICE_ID = 'SET_DEVICE_ID';
const OPEN_SBER_MODAL = 'OPEN_SBER_MODAL';
const CLOSE_SBER_MODAL = 'CLOSE_SBER_MODAL';
const OPEN_POLITIKA_MODAL = 'OPEN_POLITIKA_MODAL';
const CLOSE_POLITIKA_MODAL = 'CLOSE_POLITIKA_MODAL';
const CHANGE_AUTH_STEP = 'CHANGE_AUTH_STEP';
const CHANGE_AUTH_INFO = 'CHANGE_AUTH_INFO';
const CHANGE_AUTH_ERROR = 'CHANGE_AUTH_ERROR';
const CHANGE_AUTH_REQUEST_SENDING = 'CHANGE_AUTH_REQUEST_SENDING';
const CHANGE_ORDER_REQUEST_SENDING = 'CHANGE_ORDER_REQUEST_SENDING';
const OPEN_ERROR = 'OPEN_ERROR';
const OPEN_ERROR_STERL = 'OPEN_ERROR_STERL';
const CLOSE_ERROR = 'CLOSE_ERROR';
const CLOSE_ERROR_STERL = 'CLOSE_ERROR_STERL';
const CHANGE_USER_NAME = 'CHANGE_USER_NAME';
const CHANGE_BROADCAST_URL = 'CHANGE_BROADCAST_URL';
const CHANGE_LOTTERY_WIN = 'CHANGE_LOTTERY_WIN';
const CHANGE_LOTTERY_RANDOM = 'CHANGE_LOTTERY_RANDOM';
const CHANGE_SETS_BUY = 'CHANGE_SETS_BUY';
const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
const CHANGE_PAYMENT_TYPE_AVAILABLE = 'CHANGE_PAYMENT_TYPE_AVAILABLE';
const SET_PAYMENT_TYPE_SELECTED = 'SET_PAYMENT_TYPE_SELECTED';
const SET_IFRAME = 'SET_IFRAME';
const FORCE_UPDATE_TOKEN = 'FORCE_UPDATE_TOKEN';

export function isValidToken(token)
{
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return false;
    }
    return true;
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

let name = '', phone = '';
let at = localStorage.getItem('accessToken');
if (at) {
    name = parseJwt(at).userName;
    phone = parseJwt(at).userPhone;
}

let initialState = {
    is_loading: false,
    sushi_day: '21',
    sushi_month: 'января',
    sushi_year: '2025',
    paymentType: PAYMENT_TYPE_ONLINE,
    paymentTypeAvailable: false,
    paymentTypeSelected: false,
    soevSousId: '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8',
    citys:{
        '1':{
            name: 'Уфа', 
            name_footer: 'Уфе', 
            url: 'ufa', 
            timefrom: '10:00', 
            timeto: '23:00', 
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '1',
            restaurants:{},
            seconds_to_start:-1,
            phone:'8 (347) 299-93-30',
            phone_call:'83472999330',
            all_sets: 5000,
            address: "",
            partners: {},
            latitude: 54.735152,
            longitude: 55.958736,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushi102',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous', image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi', image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir', 
                image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех ресторанах Уфы. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        // '2':{
        //     name: 'Пермь', 
        //     name_footer: 'Перми', 
        //     url: 'perm', 
        //     timefrom: '10:00', 
        //     timeto: '23:00', 
        //     lotterybefore: '18:59',
        //     lotteryafter: '19:00', 
        //     order: '4',
        //     restaurants:{},
        //     seconds_to_start:-1,
        //     phone:'8 (342) 255-39-39',
        //     phone_call:'83422553939',
        //     all_sets: 2000,
        //     address: "",
        //     partners: {},
        //     latitude: 58.109602878324466,
        //     longitude: 56.31138555952452,
        //     roll_first_price: 0,
        //     roll_second_price: 0,
        //     roll_third_price: 0,
        //     roll_fourth_price: 0,
        //     inst_href: '',
        //     vk_href: 'https://vk.com/sushi_59',
        //     set_name: 'Сет «День суши 2025»',
        //     set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
        //     old_price: 583,
        //     new_price: 169,
        //     setsInCart: 1,
        //     max_sets_by_user: 4,
        //     setsCountNew: 99999,
        // soev_block: 0,
        //     dopProducts:{
        //         '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous', image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
        //         '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi', image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
        //         'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

        //         // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
        //     },
        //     questions:[
        //         {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
        //         {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
        //         {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
        //         {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
        //         {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
        //         {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
        //         {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
        //         {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
        //         {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
        //         {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
        //         {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
        //         {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
        //         {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
        //         {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
        //         {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
        //         {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
        //         {question: "Можно ли отменить заказ?", answer: 'Нет.'},
        //         {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
        //         {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
        //         {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
        //         {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
        //         {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
        //         {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
        //     ]
        // },
        '3':{
            name: 'Чебоксары', 
            name_footer: 'Чебоксарах', 
            url: 'cheb', 
            timefrom: '10:00', 
            timeto: '23:00', 
            lotterybefore: '16:59',
            lotteryafter: '17:00', 
            order: '6',
            restaurants:{},
            seconds_to_start:-1,
            phone:'8 (8352) 35-50-60',
            phone_call:'88352355060',
            all_sets: 350,
            address: "",
            partners: {},
            latitude: 56.09601905191455,
            longitude: 47.27704310185233,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/fujicheb',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '4':{
            name: 'Новый Уренгой', 
            name_footer: 'Новом Уренгое', 
            url: 'nur', 
            timefrom: '10:00', 
            timeto: '23:00', 
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '7',
            restaurants:{},
            seconds_to_start:-1,
            phone:'8 (3494) 92-78-58',
            phone_call:'83494927858',
            all_sets: 110,
            address: "на ул. Губкина, 19",
            partners: {},
            latitude: 66.098090,
            longitude: 76.674954,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushi_nur',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 628,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '5':{
            name: 'Анапа', 
            name_footer: 'Анапе', 
            url: 'anapa', 
            timefrom: '10:00', 
            timeto: '23:00', 
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '8',
            restaurants:{},
            seconds_to_start:-1,
            phone:'8 (903) 452-05-55',
            phone_call:'89034520555',
            all_sets: 250,
            address: "на ул. Омелькова, 28",
            partners: {},
            latitude: 44.877652,
            longitude: 37.334864,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/fuji_anapa',
            set_name: 'Набор «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 628,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
        soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '6': {
            name: 'Стерлитамак', 
            name_footer: 'Стерлитамаке', 
            url: 'str', 
            timefrom: '10:00', 
            timeto: '23:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '9',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (906) 102-33-33',
            phone_call:'89061023333',
            all_sets: 400,
            address: "",
            partners: {},
            latitude: 53.629036, 
            longitude: 55.910550,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_strh',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
        soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '7': {
            name: 'Ижевск', 
            name_footer: 'Ижевске', 
            url: 'izh', 
            timefrom: '10:00', 
            timeto: '23:00',
            lotterybefore: '17:59',
            lotteryafter: '18:00', 
            order: '10',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (3412) 32-27-27',
            phone_call:'83412322727',
            all_sets: 350,
            address: "",
            partners: {},
            latitude: 56.861451,
            longitude: 53.211320,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_izh',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '10': {
            name: 'Новороссийск', 
            name_footer: 'Новороссийске', 
            url: 'nvrsk', 
            timefrom: '11:00', 
            timeto: '23:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '11',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (903) 453-01-11',
            phone_call:'8 (903) 453-01-11',
            all_sets: 250,
            address: " на Анапском шоссе, 4",
            partners: {},
            latitude: 44.898777,
            longitude: 37.345113,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_nvrsk',
            set_name: 'Набор «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
            {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
            {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
            {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
            {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
            {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
            {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
            {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
            {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
            {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
            {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
            {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
            {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
            {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
            {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
            {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
            {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
            {question: "Можно ли отменить заказ?", answer: 'Нет.'},
            {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
            {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
            {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
            {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
            {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
            {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
        ]
        },

        // '11': {
        //     name: 'Кудрово', 
        //     name_footer: 'Кудрово', 
        //     url: 'kdr', 
        //     timefrom: '10:00', 
        //     timeto: '23:00',
        //     lotterybefore: '16:59',
        //     lotteryafter: '17:00', 
        //     order: '12',
        //     restaurants: {}, 
        //     seconds_to_start: -1, 
        //     phone:'8 (812) 213-0-123',
        //     phone_call:'88122130123',
        //     all_sets: 110,
        //     address: "на ул. Пражская, 3",
        //     partners: {},
        //     latitude: 59.908629,
        //     longitude: 30.509428,
        //     roll_first_price: 0,
        //     roll_second_price: 0,
        //     roll_third_price: 0,
        //     roll_fourth_price: 0,
        //     inst_href: '',
        //     vk_href: 'https://vk.com/sushifuji_kdr',
        //     set_name: 'Сет «День суши 2025»',
        //     set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
        //     old_price: 638,
        //     new_price: 169,
        //     setsInCart: 1,
        //     max_sets_by_user: 4,
        //     setsCountNew: 99999,
        // soev_block: 0,
        //     dopProducts:{
        //         '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
        //         '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
        //         'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

        //         // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
        //     },
        //     questions:[
        //         {question: "Что такое день суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},  
        //         {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
        //         {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
        //         {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
        //         {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах города. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена.'},
        //         {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
        //         {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа. В случае ограничений оплатить заказ можно на кассе при получении заказа.'},
        //         {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа.'},
        //         {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
        //         {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
        //         {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
        //         {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
        //         {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
        //         {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
        //         {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
        //         {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
        //         {question: "Можно ли отменить заказ?", answer: 'Нет.'},
        //         {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
        //         {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
        //         {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
        //         {question: "Как узнать, сколько суши наборов осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
        //         {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
        //         {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
        //     ]
        // },
        '12': {
            name: 'Иглино', 
            name_footer: 'Иглино', 
            url: 'igl', 
            timefrom: '17:00', 
            timeto: '23:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '3',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (906) 102-55-57',
            phone_call:'89061025557',
            all_sets: 200,
            address: "на ул. Горького, 2/2",
            partners: {},
            latitude: 54.741256,
            longitude: 55.992623,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_iglino',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
        soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '13': {
            name: 'Салават', 
            name_footer: 'Салавате', 
            url: 'slvt', 
            timefrom: '10:00', 
            timeto: '23:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '13',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (906) 102-33-30',
            phone_call:'89061023330',
            all_sets: 120,
            address: "на бульваре Космонавтов, 13",
            partners: {},
            latitude: 53.352386,
            longitude: 55.926504,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_slvt',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
        soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        '14': {
            name: 'Нагаево', 
            name_footer: 'Нагаево', 
            url: 'ngv', 
            timefrom: '17:00', 
            timeto: '23:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '2',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (903) 310-30-60',
            phone_call:'89033103060',
            all_sets: 120,
            address: "ул. Советская, 26/1",
            partners: {},
            latitude: 54.623269,
            longitude: 56.106850,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_ngv',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
        soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
       '15': {
            name: 'Темрюк', 
            name_footer: 'Темрюке', 
            url: 'temryuk', 
            timefrom: '10:00', 
            timeto: '22:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '15',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (909) 44-33-440',
            phone_call:'89094433440',
            all_sets: 110,
            address: "ул. Кирова, 1",
            partners: {},
            latitude: 45.281403,
            longitude: 37.366367,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuj_temryuk',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 628,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous', image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi', image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        },
        
        // '16': {
        //     name: 'Фролы', 
        //     name_footer: ' Фролах', 
        //     url: 'froly', 
        //     timefrom: '10:00', 
        //     timeto: '22:00',
        //     lotterybefore: '18:59',
        //     lotteryafter: '19:00', 
        //     order: '15',
        //     restaurants: {}, 
        //     seconds_to_start: -1, 
        //     phone:'8 (909) 44-33-440',
        //     phone_call:'89094433440',
        //     all_sets: 110,
        //     address: "ул. Кирова, 1",
        //     partners: {},
        //     latitude: 45.281403,
        //     longitude: 37.366367,
        //     roll_first_price: 0,
        //     roll_second_price: 0,
        //     roll_third_price: 0,
        //     roll_fourth_price: 0,
        //     inst_href: '',
        //     vk_href: 'https://vk.com/sushifuj_temryuk',
        //     set_name: 'Сет «День суши 2025»',
        //     set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
        //     old_price: 583,
        //     new_price: 169,
        //     setsInCart: 1,
        //     max_sets_by_user: 4,
        //     setsCountNew: 99999,
        // soev_block: 0,
        //     dopProducts:{
        //         '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous', image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
        //         '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi', image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
        //         'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

        //         // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul', image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
        //         'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
        //     },
        //     questions:[
        //         {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
        //         {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
        //         {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
        //         {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
        //         {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
        //         {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
        //         {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
        //         {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
        //         {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
        //         {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
        //         {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
        //         {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
        //         {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
        //         {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
        //         {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
        //         {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
        //         {question: "Можно ли отменить заказ?", answer: 'Нет.'},
        //         {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
        //         {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
        //         {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
        //         {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
        //         {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
        //         {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
        //     ]
        // }
        
        '17': {
            name: 'Крымск', 
            name_footer: 'Крымске', 
            url: 'krymsk', 
            timefrom: '10:00', 
            timeto: '22:00',
            lotterybefore: '18:59',
            lotteryafter: '19:00', 
            order: '16',
            restaurants: {}, 
            seconds_to_start: -1, 
            phone:'8 (903) 45-330-30',
            phone_call:'89034533030',
            all_sets: 110,
            address: "ул. Синёва, 11",
            partners: {},
            latitude: 44.929683,
            longitude: 37.988065,
            roll_first_price: 0,
            roll_second_price: 0,
            roll_third_price: 0,
            roll_fourth_price: 0,
            inst_href: '',
            vk_href: 'https://vk.com/sushifuji_krymsk',
            set_name: 'Сет «День суши 2025»',
            set_id: 'a3fb2614-b778-47b4-a686-31e93a64832b',
            old_price: 588,
            new_price: 169,
            setsInCart: 1,
            max_sets_by_user: 4,
            setsCountNew: 99999,
            soev_block: 0,
            dopProducts:{
                '4b7d6daf-5354-4e9d-95f9-dd2cd11001c8':{count: 1,price:'45', name:'Соевый соус', nickname:'soev_sous',image:'https://sushifuji.ru/public/images/menu/products/1/4b7d6daf-5354-4e9d-95f9-dd2cd11001c8.jpg'},
                '56f8c715-75fa-41fd-adc4-7559ddb8b409':{count: 0,price:'35', name:'Васаби 6 гр.', nickname:'vasabi',image:'https://sushifuji.ru/public/images/menu/products/1/56f8c715-75fa-41fd-adc4-7559ddb8b409.jpg'},
                'b4d4c85f-9eb6-4e09-b363-0042be254498':{count: 0,price:'45', name:'Имбирь 20 гр.', nickname:'imbir',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '5ecde75d-21b2-44d9-a79b-1c3ae19bf14e':{count: 0,price:'45', name:'Соус Спайс 40 гр', nickname:'spais',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '264da2bd-1288-4a38-a72d-4a92879eeb20':{count: 0,price:'45', name:'Соус Унаги 40 гр.', nickname:'unagi',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'c38f3430-a215-4c1f-8ace-193336f6203c':{count: 0,price:'45', name:'Соус Ореховый 30 гр', nickname:'oreh',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},

                // '1c32135b-2aeb-4aa1-b391-dfa7a975fb00':{count: 0,price:'45', name:'Соус Мега Цезарь 40 гр', nickname:'cezar',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // 'fbfd3bd2-7fa2-4378-97e4-8e06341705d5':{count: 0,price:'45', name:'Соус Фирменный 40 гр', nickname:'firm',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                // '0694959e-8cca-4598-9512-233cd9142af8':{count: 0,price:'45', name:'Соус Соул 40 гр', nickname:'soul',image:'https://sushifuji.ru/public/images/menu/products/1/b4d4c85f-9eb6-4e09-b363-0042be254498.jpg'},
                'dc718274-73f3-44fd-b3cd-5bb5fee579a4':{count: 0,price:'10', name:'Палочки одноразовые', nickname:'pal', image:'https://sushifuji.ru/public/images/menu/products/1/dc718274-73f3-44fd-b3cd-5bb5fee579a4.jpg'}
            },
            questions:[
                {question: "Что такое День суши?", answer: 'Это день, когда Фудзияма делает гигантскую скидку на набор из роллов. Чтобы как можно больше людей смогли попробовать вкусные роллы от Фудзиямы. Можно заказать суши набор из 16 шт. роллов всего за 169 ₽ навынос.'},
                {question: "Сколько блюд я могу добавить в заказ?", answer: 'До четырех суши наборов в одном заказе.'},
                {question: "Что туда входит?", answer: 'WHAT_INCLUDE'},
                {question: "Входит ли в заказ соевый соус, имбирь и васаби?", answer: 'Нет, соевый соус, имбирь и васаби покупаются отдельно. В набор входит одна пара палочек.'},
                {question: "В каком ресторане будет?", answer: 'Суши наборы будут доступны во всех наших ресторанах. Количество наборов в каждом ресторане ограничено, и акция может быть приостановлена'},
                {question: "Как это будет проходить?", answer: 'День суши будет в формате онлайн - необходимо сделать заказ на сайте den-sushi.ru, оплатить его онлайн и ожидать очереди готовности заказа.'},
                {question: "Как оплатить заказ?", answer: 'Онлайн, можно ввести на сайте данные карты для оплаты заказа.'},
                {question: "Как я узнаю, что заказ готов?", answer: 'За 30 минут до готовности заказа Вам придет СМС-оповещение. При оформлении заказа будет прописано ориентировочное время приготовления заказа'},
                {question: "Как мне получить заказ?", answer: 'Самовывозом в том ресторане, который вы указали при заказе.'},
                {question: "Можно на доставку?", answer: 'Заказы забираются только самовывозом.'},
                {question: "Можно расплатиться бонусами?", answer: 'Нет, акции не суммируются с оплатой бонусами.'},
                {question: "Можно ли заказать что-то помимо суши набора за 169 ₽?", answer: 'Да, в ресторанах будет действовать все основное меню. Заказы на основное меню принимаются по телефону 8-800-333-800-4, на сайте sushifuji.ru или на кассе ресторанов.'},
                {question: "Можно ли совместить в одном заказе суши набор за 169 ₽ и заказ по основному меню?", answer: 'Нет.'},
                {question: "Можно ли сделать заказ по акции “День суши” через кассира или по телефону?", answer: 'Нет.'},
                {question: "Как отследить статус заказа?", answer: 'На этом сайте вы увидите ваш статус заказа.'},
                {question: "До скольки часов будет проводиться акция?", answer: 'До тех пор, пока не реализуется заявленное количество акционных наборов “День суши”.'},
                {question: "Можно ли отменить заказ?", answer: 'Нет.'},
                {question: "Можно ли оформленный заказ перенести на другой ресторан?", answer: 'Нет, заказ можно забрать только в том ресторане, который был выбран при оформлении.'},
                {question: "Можно ли оформить заказ по акции “День суши” на определенное время?", answer: 'Нет.'},
                {question: "Можно ли оформить заказ заранее, за день?", answer: 'Нет.'},
                {question: "Как узнать, сколько наборов суши осталось?", answer: 'На этом сайте вы увидите количество оставшихся наборов.'},
                {question: "Можно ли забрать заказ позднее указанного времени?", answer: 'Да, но не более, чем через час после приготовления. Обращаем ваше внимание, что роллы могут быть остывшими.'},
                {question: "Могу ли я оформить несколько заказов на День суши?", answer: 'Нет, на один номер телефона можно оформить только один заказ.'}
            ]
        }

    },
    currentCity: localStorage.getItem('city_id') ? localStorage.getItem('city_id') : -1,
    currentRest: 0,

    auth_modal_show: false,
    error_login_text: '',
    order_modal_show: false,
    roll_first_modal_show: false,
    roll_second_modal_show: false,
    roll_third_modal_show: false,
    roll_fourth_modal_show: false,
    soev_sous_show: false,
    imbir_show: false,
    vasabi_show: false,

    // spais_show: false,
    // unagi_show: false,
    // oreh_show: false,
    // cezar_show: false,
    // firm_show: false,
    // soul_show: false,

    pal_show: false,

    // referral_show: false,
    
    sber_modal_show: false,
    politika_modal_show: false,
    error_modal_show: false,
    error_modal_show_sterl: false,

    citys_modal_show: localStorage.getItem('city_id') ? false : true,
    isSelectCity: localStorage.getItem('city_id') ? true : false,
    authStep: 1,
    auth_request_sending: false,
    order_request_sending: false,
    phone: phone,
    password: '',
    name: name,
    nameAT: name,
    canSendPassword: true,
    sendPasswordTimer: 0,
    auth_info_block: '',
    auth_error_block: '',
    setsCount: 99999,
    device_id: null,
    broadcast_url: "https://www.youtube.com/watch?v=yHZAJKFfZxo",


    countsAllClients: "",
    countsReceivedClients: "",
    linkForOnlinePayment: "",
    orderAddress: "",
    orderCountSets: 0,
    orderIsClose: false,
    orderIsReady: false,
    orderIsWaitPaid: false,
    orderNumber: 0,
    orderNumberQueue: 0,
    orderResolution: true,
    orderStatus: "",
    orderSum: 0,
    orderTimePeriod: "",
    queueList: [],
    lotteryList: [],
    isAuth: localStorage.getItem('accessToken') ? true : false,
    canTryOrder: true,
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    next_page: null,
    iframe: false,
};

if (!initialState.citys.hasOwnProperty(initialState.currentCity)) {
    initialState.currentCity = Object.keys(initialState.citys)[0];
    localStorage.setItem('city_id', Object.keys(initialState.citys)[0]);
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                is_loading: action.value
            };
            case NEXT_PAGE:
            return {
                ...state,
                next_page: action.value
            };
        case CLOSE_AUTH_MODAL:
            return {
                ...state,
                auth_modal_show: false
            };
        case OPEN_AUTH_MODAL:
            return {
                ...state,
                auth_modal_show: true,
            };
        case CHANGE_AUTH_MODAL_PHONE:
            return {
                ...state,
                phone: action.value
            };
        case CHANGE_AUTH_MODAL_PASSWORD:
            return {
                ...state,
                password: action.value
            };
        case CHANGE_ISAUTH:
            localStorage.setItem('accessToken', action.accessToken);
            localStorage.setItem('refreshToken', action.refreshToken);

             let data = {
                ...state,
                auth_modal_show: false,
                isAuth: true,
                name: parseJwt(action.accessToken).userName,
                nameAT: parseJwt(action.accessToken).userName,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken
            };

            if (null !== state.next_page && OPEN_ORDER_MODAL === state.next_page) data.order_modal_show = true;

            return data;
        case CHANGE_SETSCOUNT:
            return {
                ...state,
                setsCount: action.value
            };
        case CHANGE_SETSCOUNTNEW:
            return {
                ...state,
                setsCountNew: action.value
            };
        case INCREASE_SETS_IN_CART: {
            let city = state.citys[state.currentCity];
            if (city.max_sets_by_user > city.setsInCart) {
                if(city.soev_block===0) city.dopProducts[state.soevSousId]['count'] = city.setsInCart+1;
                return {
                    ...state,
                    citys: { ...state.citys, [state.currentCity]: { ...state.citys[state.currentCity], setsInCart: city.setsInCart + 1 } }
                }
            } else {
                return {
                    ...state,
                }
            }
        }
        case DECREASE_SETS_IN_CART: {
            let city = state.citys[state.currentCity];
            if (city.setsInCart > 1) {
                if(city.soev_block===0) city.dopProducts[state.soevSousId]['count'] = city.setsInCart-1;
                return {
                    ...state,
                    citys: { ...state.citys, [state.currentCity]: { ...state.citys[state.currentCity], setsInCart: city.setsInCart - 1 } }
                }
            } else {
                return state;
            }
        }
        case INCREASE_DOPS_IN_CART: {
            let city = state.citys[state.currentCity];
            let product = city.dopProducts[action.id];
            product.count++;
            if(action.id === state.soevSousId) city.soev_block = 1;

            return {
                ...state,
                citys: { ...state.citys, [state.currentCity]: { ...city, dopProducts: { ...city.dopProducts, [action.id]: product } } }
            }
        }
        case DECREASE_DOPS_IN_CART: {
            let city = state.citys[state.currentCity];
            let product = city.dopProducts[action.id];
            if (product.count > 0) product.count--;
            if(action.id === state.soevSousId) city.soev_block = 1;
            return {
                ...state,
                citys: { ...state.citys, [state.currentCity]: { ...city, dopProducts: { ...city.dopProducts, [action.id]: product } } }
            }
        }
        case CLOSE_ORDER_MODAL:
            return {
                ...state,
                order_modal_show: false,
            }
        case OPEN_ORDER_MODAL:
            return {
                ...state,
                order_modal_show: true,
            }
        case SET_CURRENT_RESTAURANT:
            return {
                ...state,
                currentRest: action.value,
            }
        case CHANGE_ERROR_LOGIN_TEXT:
            return {
                ...state,
                error_login_text: action.value
            }
        case CHANGE_PASSWORD_TIMER:
            if (action.value == null) {
                if (state.sendPasswordTimer > 0) {
                    return {
                        ...state,
                        sendPasswordTimer: state.sendPasswordTimer - 1
                    }
                } else {
                    if (state.canSendPassword == false) {
                        return {
                            ...state,
                            canSendPassword: true
                        }
                    }
                    return state;
                }
            } else {
                return {
                    ...state,
                    sendPasswordTimer: action.value,
                    canSendPassword: action.value ? false : true
                }
            }
        case CHANGE_SEND_PASSWORD_FLAG:
            return {
                ...state,
                canSendPassword: action.value
            }
        case OPEN_CITYS_POPUP:
            return {
                ...state,
                citys_modal_show: true
            }
        case CLOSE_CITYS_POPUP:
            localStorage.setItem('city_id', 1);
            return {
                ...state,
                citys_modal_show: false
            }
        case CHANGE_CITY:
            localStorage.setItem('city_id', action.value);
            return {
                ...state,
                currentCity: action.value,
                currentRest: 0,
                citys_modal_show: false,
                setsCount: state.citys[action.value].setsCountNew,
                paymentType: PAYMENT_TYPE_ONLINE,
                paymentTypeAvailable: false,
                paymentTypeSelected: false,
            }
        case SETS_CHECKER:
            if (state.setsCount == 99999) {
                return {
                    ...state,
                    setsCount: state.citys[state.currentCity].setsCountNew
                }
            }
            if (state.setsCount != state.citys[state.currentCity].setsCountNew) {
                return {
                    ...state,
                    setsCount: state.setsCount > state.citys[state.currentCity].setsCountNew ? state.setsCount - 1 : state.setsCount + 1
                }
            }
            break;
        case CHANGE_REMAINING_SETS_IN_CITY:
            return {
                ...state,
                citys: {
                    ...state.citys,
                    [action.city_id]: { ...state.citys[action.city_id], setsCountNew: action.sets_value }
                }
            }
        case SET_RESTAURANTS: {
            let good_rests = {};
            let currentRest = state.currentRest;
            // let currentRest = 0;

            if (Object.keys(action.rests).length == 1) {
                currentRest = action.rests[Object.keys(action.rests)[0]].id;
            }

            for (let key in action.rests) {
                good_rests[action.rests[key].id] = action.rests[key];
            }
            return {
                ...state,
                citys: { ...state.citys, [action.city_id]: { ...state.citys[action.city_id], restaurants: good_rests } },
                currentRest
            }
            break;
        }
        case CHANGE_TIMER_TO_START: {
            if (action.seconds == -1 && state.citys[state.currentCity].seconds_to_start > 0) {
                return {
                    ...state,
                    citys: { ...state.citys, [state.currentCity]: { ...state.citys[state.currentCity], seconds_to_start: state.citys[state.currentCity].seconds_to_start - 1 } }
                }
            }
            if (action.seconds >= 0) {
                return {
                    ...state,
                    citys: { ...state.citys, [state.currentCity]: { ...state.citys[state.currentCity], seconds_to_start: action.seconds } }
                }
            }
            break;
        }
        case CLOSE_ROLL_FIRST:
            return { ...state, roll_first_modal_show: false }
        case OPEN_ROLL_FIRST:
            return { ...state, roll_first_modal_show: true }
        case CLOSE_ROLL_SECOND:
            return { ...state, roll_second_modal_show: false }
        case OPEN_ROLL_SECOND:
            return { ...state, roll_second_modal_show: true }
        case CLOSE_ROLL_THIRD:
            return { ...state, roll_third_modal_show: false }
        case OPEN_ROLL_THIRD:
            return { ...state, roll_third_modal_show: true }
        case CLOSE_ROLL_FOURTH:
            return { ...state, roll_fourth_modal_show: false }
        case OPEN_ROLL_FOURTH:
            return { ...state, roll_fourth_modal_show: true }
        case CLOSE_SOEV_SOUS:
            return { ...state, soev_sous_show: false }
        case OPEN_SOEV_SOUS:
            return { ...state, soev_sous_show: true }
        case CLOSE_IMBIR:
            return { ...state, imbir_show: false }
        case OPEN_IMBIR:
            return { ...state, imbir_show: true }
        case CLOSE_VASABI:
            return { ...state, vasabi_show: false }
        case OPEN_VASABI:
            return { ...state, vasabi_show: true }
        // case CLOSE_SPAIS:
        //     return { ...state, spais_show: false }
        // case OPEN_SPAIS:
        //     return { ...state, spais_show: true }
        // case CLOSE_UNAGI:
        //     return { ...state, unagi_show: false }
        // case OPEN_UNAGI:
        //     return { ...state, unagi_show: true }
        // case CLOSE_OREH:
        //     return { ...state, oreh_show: false }
        // case OPEN_OREH:
        //     return { ...state, oreh_show: true }
        // case CLOSE_CEZAR:
        //     return { ...state, cezar_show: false }
        // case OPEN_CEZAR:
        //     return { ...state, cezar_show: true }
        // case CLOSE_FIRM:
        //     return { ...state, firm_show: false }
        // case OPEN_FIRM:
        //     return { ...state, firm_show: true }
        // case CLOSE_SOUL:
        //     return { ...state, soul_show: false }
        // case OPEN_SOUL:
        //     return { ...state, soul_show: true }
        case CLOSE_PAL:
            return { ...state, pal_show: false }
        case OPEN_PAL:
            return { ...state, pal_show: true }
        // case CLOSE_REFERRAL:
        //     return { ...state, referral_show: false }
        // case OPEN_REFERRAL:
        //     return { ...state, referral_show: true }




        case CHANGE_orderIsWaitPaid:
            return {
                ...state,
                orderIsWaitPaid: action.value
            }
        case CHANGE_orderIsReady:
            return {
                ...state,
                orderIsReady: action.value
            }
        case CHANGE_orderIsClose:
            return {
                ...state,
                orderIsClose: action.value
            }
        case CHANGE_orderResolution:
            return {
                ...state,
                orderResolution: action.value
            }
        case CHANGE_linkForOnlinePayment:
            return {
                ...state,
                linkForOnlinePayment: action.value
            }
        case API_ORDERS: {
            return {
                ...state,
                countsAllClients: action.value.countsAllClients,
                countsReceivedClients: action.value.countsReceivedClients,
                linkForOnlinePayment: action.value.linkForOnlinePayment,
                orderAddress: action.value.orderAddress,
                orderCountSets: action.value.orderCountSets,
                orderIsClose: action.value.orderIsClose,
                orderIsReady: action.value.orderIsReady,
                orderIsWaitPaid: action.value.orderIsWaitPaid,
                orderNumber: action.value.orderNumber,
                orderNumberQueue: action.value.orderNumberQueue,
                orderResolution: action.value.orderResolution,
                orderStatus: action.value.orderStatus,
                orderSum: action.value.orderSum,
                orderTimePeriod: action.value.orderTimePeriod,
                queueList: action.value.queueList,
                lotteryList: action.value.lotteryList,
            }
        }
        case LOGOUT:
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            return {
                ...state,
                isAuth: false,
                countsAllClients: "",
                countsReceivedClients: "",
                linkForOnlinePayment: "",
                orderAddress: "",
                orderCountSets: 0,
                orderIsClose: false,
                orderIsReady: false,
                orderIsWaitPaid: false,
                orderNumber: 0,
                orderNumberQueue: 0,
                orderResolution: true,
                orderStatus: "",
                orderSum: 0,
                orderTimePeriod: "",
                queueList: [],
                lotteryList: [],
                accessToken: null,
                refreshToken: null,
                authStep: 1,
            }
        case SET_DEVICE_ID:
            return {
                ...state,
                device_id: action.value
            }
        case OPEN_SBER_MODAL: {
            return {
                ...state,
                sber_modal_show: true
            }
        }
        case CLOSE_SBER_MODAL: {
            return {
                ...state,
                sber_modal_show: false
            }
        }
        case OPEN_POLITIKA_MODAL: {
            return {
                ...state,
                politika_modal_show: true
            }
        }
        case CLOSE_POLITIKA_MODAL: {
            return {
                ...state,
                politika_modal_show: false
            }
        }
        case CHANGE_AUTH_STEP:
            return {
                ...state,
                authStep: action.value
            }
        case CHANGE_AUTH_INFO:
            return {
                ...state,
                auth_info_block: action.value
            }
        case CHANGE_AUTH_ERROR:
            return {
                ...state,
                auth_error_block: action.value
            }
        case CHANGE_AUTH_REQUEST_SENDING: {
            return {
                ...state,
                auth_request_sending: action.value
            }
        }
        case CHANGE_ORDER_REQUEST_SENDING: {
            return {
                ...state,
                order_request_sending: action.value
            }
        }
        case OPEN_ERROR: {
            return {
                ...state,
                error_modal_show: true
            }
        }
        case OPEN_ERROR_STERL: {
            return {
                ...state,
                error_modal_show_sterl: true
            }
        }
        case CLOSE_ERROR: {
            return {
                ...state,
                error_modal_show: false
            }
        }
        case CLOSE_ERROR_STERL: {
            return {
                ...state,
                error_modal_show_sterl: false
            }
        }
        case CHANGE_USER_NAME: {
            return {
                ...state,
                name: action.value
            }
        }
        case CHANGE_BROADCAST_URL:
            return {
                ...state,
                broadcast_url: action.value
            }
        case CHANGE_LOTTERY_WIN:
            return {
                ...state,
                lotteryAllListWinners: action.value
            }
        case CHANGE_LOTTERY_RANDOM:
            return {
                ...state,
                lotteryRandom: action.value
            }
        case CHANGE_SETS_BUY:
            return {
                ...state,
                citys: {
                    ...state.citys,
                    [action.city_id]: { ...state.citys[action.city_id], sets_buy: action.sets_buy }
                }
            }
        case CHANGE_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: action.paymentType
            }
        case CHANGE_PAYMENT_TYPE_AVAILABLE:
            return {
                ...state,
                paymentTypeAvailable: action.paymentTypeAvailable
            }
        case SET_PAYMENT_TYPE_SELECTED:
            return {
                ...state,
                paymentTypeSelected: action.paymentTypeSelected
            }
        case SET_IFRAME:
            return {
                ...state,
                iframe: action.value
            }
        case FORCE_UPDATE_TOKEN:
            refreshTokenF(localStorage.getItem('accessToken'), localStorage.getItem('refreshToken'));

            return {
                ...state,
            }
        default:
            return state;
    }
    return state;
}

export const forceUpdateAccessToken = (value) => ({ type: FORCE_UPDATE_TOKEN, value });
export const setIframe = (value) => ({ type: SET_IFRAME, value });
export const changeIsLoading = (value) => ({ type: IS_LOADING, value });
export const nextPage = (value) => ({ type: NEXT_PAGE, value });
export const close_auth_modal = () => ({ type: CLOSE_AUTH_MODAL });
export const open_auth_modal = () => ({ type: OPEN_AUTH_MODAL });
export const change_auth_modal_phone = (value) => ({ type: CHANGE_AUTH_MODAL_PHONE, value });
export const change_auth_modal_password = (value) => ({ type: CHANGE_AUTH_MODAL_PASSWORD, value });
export const change_sets_count = (value) => ({ type: CHANGE_SETSCOUNT, value });
export const change_sets_count_new = (value) => ({ type: CHANGE_SETSCOUNTNEW, value });
export const increase_sets = () => ({ type: INCREASE_SETS_IN_CART });
export const decrease_sets = () => ({ type: DECREASE_SETS_IN_CART });
export const increase_dops = (id) => ({ type: INCREASE_DOPS_IN_CART, id });
export const decrease_dops = (id) => ({ type: DECREASE_DOPS_IN_CART, id });
export const auth_client = (accessToken, refreshToken) => ({ type: CHANGE_ISAUTH, accessToken, refreshToken });
export const close_order = () => ({ type: CLOSE_ORDER_MODAL });
export const open_order = () => ({ type: OPEN_ORDER_MODAL });
export const set_restaurant = (rest_id) => ({ type: SET_CURRENT_RESTAURANT, value: rest_id });
export const change_error_login_text = (value) => ({ type: CHANGE_ERROR_LOGIN_TEXT, value });
export const change_password_timer = (value = null) => ({ type: CHANGE_PASSWORD_TIMER, value });
export const change_password_flag = (value) => ({ type: CHANGE_SEND_PASSWORD_FLAG, value });

export const open_citys_popup = () => ({ type: OPEN_CITYS_POPUP });
export const close_citys_popup = () => ({ type: CLOSE_CITYS_POPUP });
export const change_city = (value) => ({ type: CHANGE_CITY, value });
export const sets_checker = () => ({ type: SETS_CHECKER });
export const change_remaining_sets = (sets_value, city_id) => ({ type: CHANGE_REMAINING_SETS_IN_CITY, sets_value, city_id });
export const set_restaurants = (city_id, rests) => ({ type: SET_RESTAURANTS, city_id, rests });
export const change_timer_to_start = (seconds) => ({ type: CHANGE_TIMER_TO_START, seconds });

export const close_roll_first = () => ({ type: CLOSE_ROLL_FIRST });
export const open_roll_first = () => ({ type: OPEN_ROLL_FIRST });
export const close_roll_second = () => ({ type: CLOSE_ROLL_SECOND });
export const open_roll_second = () => ({ type: OPEN_ROLL_SECOND });
export const close_roll_third = () => ({ type: CLOSE_ROLL_THIRD });
export const open_roll_third = () => ({ type: OPEN_ROLL_THIRD });
export const close_roll_fourth = () => ({ type: CLOSE_ROLL_FOURTH });
export const open_roll_fourth = () => ({ type: OPEN_ROLL_FOURTH });
export const open_soev_sous = () => ({ type: OPEN_SOEV_SOUS });
export const close_soev_sous = () => ({ type: CLOSE_SOEV_SOUS });
export const open_imbir = () => ({ type: OPEN_IMBIR });
export const close_imbir = () => ({ type: CLOSE_IMBIR });
export const open_vasabi = () => ({ type: OPEN_VASABI });
export const close_vasabi = () => ({ type: CLOSE_VASABI });
// export const open_spais = () => ({ type: OPEN_SPAIS });
// export const close_spais = () => ({ type: CLOSE_SPAIS });
// export const open_unagi = () => ({ type: OPEN_UNAGI });
// export const close_unagi = () => ({ type: CLOSE_UNAGI });
// export const open_oreh = () => ({ type: OPEN_OREH });
// export const close_oreh = () => ({ type: CLOSE_OREH });
// export const open_cezar = () => ({ type: OPEN_CEZAR });
// export const close_cezar = () => ({ type: CLOSE_CEZAR });
// export const open_firm = () => ({ type: OPEN_FIRM });
// export const close_firm = () => ({ type: CLOSE_FIRM });
// export const open_soul = () => ({ type: OPEN_SOUL });
// export const close_soul = () => ({ type: CLOSE_SOUL });
export const open_pal = () => ({ type: OPEN_PAL });
export const close_pal = () => ({ type: CLOSE_PAL });

// export const open_referral = () => ({ type: OPEN_REFERRAL });
// export const close_referral = () => ({ type: CLOSE_REFERRAL });

export const open_sber_modal = () => ({ type: OPEN_SBER_MODAL });
export const close_sber_modal = () => ({ type: CLOSE_SBER_MODAL });

export const open_politika_modal = () => ({ type: OPEN_POLITIKA_MODAL });
export const close_politika_modal = () => ({ type: CLOSE_POLITIKA_MODAL });

export const change_orderIsWaitPaid = (value) => ({ type: CHANGE_orderIsWaitPaid, value });
export const change_orderIsReady = (value) => ({ type: CHANGE_orderIsReady, value });
export const change_orderIsClose = (value) => ({ type: CHANGE_orderIsClose, value });
export const change_orderResolution = (value) => ({ type: CHANGE_orderResolution, value });
export const change_linkForOnlinePayment = (value) => ({ type: CHANGE_linkForOnlinePayment, value });
export const api_orders = (value) => ({ type: API_ORDERS, value });
export const logout = () => ({ type: LOGOUT });
export const change_auth_step = (value) => ({ type: CHANGE_AUTH_STEP, value });
export const change_auth_info = (value) => ({ type: CHANGE_AUTH_INFO, value });
export const change_auth_error = (value) => ({ type: CHANGE_AUTH_ERROR, value });
export const change_auth_request_sending = (value) => ({ type: CHANGE_AUTH_REQUEST_SENDING, value });
export const change_order_request_sending = (value) => ({ type: CHANGE_ORDER_REQUEST_SENDING, value });
export const open_error = () => ({ type: OPEN_ERROR });
export const open_error_sterl = () => ({ type: OPEN_ERROR_STERL });
export const close_error = () => ({ type: CLOSE_ERROR });
export const close_error_sterl = () => ({ type: CLOSE_ERROR_STERL });
export const change_user_name = (value) => ({ type: CHANGE_USER_NAME, value });
export const change_broadcast_url = (value) => ({ type: CHANGE_BROADCAST_URL, value });
export const change_lottery_win = (value) => ({ type: CHANGE_LOTTERY_WIN, value });
export const change_lottery_random = (value) => ({ type: CHANGE_LOTTERY_RANDOM, value });
export const change_sets_buy = (sets_buy, city_id) => ({ type: CHANGE_SETS_BUY, sets_buy, city_id });
export const change_payment_type = (paymentType) => ({ type: CHANGE_PAYMENT_TYPE, paymentType });
export const change_payment_type_available = (paymentTypeAvailable) => ({ type: CHANGE_PAYMENT_TYPE_AVAILABLE, paymentTypeAvailable });
export const setPaymentTypeSelected = (paymentTypeSelected) => ({ type: SET_PAYMENT_TYPE_SELECTED, paymentTypeSelected });

export const cities_update_thunk = () => (dispatch, getState) => {
    let city_id = getState().authPage.currentCity;
    $api.get('/cities/' + city_id).then((response) => {
        //console.log(response);
        if (typeof response.data.daySushiCount === "number") { dispatch(change_remaining_sets(response.data.daySushiCount, city_id)); }
        if (typeof response.data.daySushiTimer === "number") { dispatch(change_timer_to_start(response.data.daySushiTimer)); }
        if (typeof response.data.lotteryAllListWinners === "object") { dispatch(change_lottery_win(response.data.lotteryAllListWinners)); }
        if (typeof response.data.lotteryRandom === "object") { dispatch(change_lottery_random(response.data.lotteryRandom)); }
        //if(typeof response.data.daySushiTimer === "number"){dispatch(change_timer_to_start(0));}
        if (typeof response.data.broadcast_url === "string") { dispatch(change_broadcast_url(response.data.broadcast_url)); }
        if (typeof response.data.daySushiBuy === "number") { dispatch(change_sets_buy(response.data.daySushiBuy, city_id)); }
    });
}

export const check_orders_thunk = () => (dispatch, getState) => {
    let local_state = getState();

    if (local_state.authPage.isAuth && local_state.authPage.accessToken) {
        $api.get('/orders?cityId=' + local_state.authPage.currentCity + '&userAccessToken=' + localStorage.getItem('accessToken')).then((response) => {
            dispatch(api_orders(response.data));
        }).catch(function (error) {
            if (error.response.data.code == 401 && error.response.data.tokenExpired == true) {
                let new_tokens = refreshTokenF(localStorage.getItem('accessToken'), localStorage.getItem('refreshToken'));
                if (new_tokens['result'] == true) {
                    dispatch(auth_client(new_tokens.accessToken, new_tokens.refreshToken));
                    $api.get('/orders?cityId=' + local_state.authPage.currentCity + '&userAccessToken=' + new_tokens.accessToken).then((response) => {
                        dispatch(api_orders(response.data));
                    })
                } else {
                    dispatch(logout());
                }
            }
        });
    }
}
function refreshTokenF(accessToken, refreshToken) {
    let params = {
        accessToken,
        refreshToken
    }

    let appdeviceid = localStorage.getItem('appdeviceid');
    if (!appdeviceid) {
        appdeviceid = (new Fingerprint2()).getSync().fprint;
        localStorage.setItem('appdeviceid', appdeviceid);
    }

    let xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api.sushifuji.ru/v1/auth/refresh', false);
    xhr.setRequestHeader('Authorization', 'Bearer bae7295d-df10-11eb-8d7a-fa163e6e6076');
    xhr.setRequestHeader('appplatformname', 'Web');
    xhr.setRequestHeader('appplatformversion', '1');
    xhr.setRequestHeader('appversion', '1');
    xhr.setRequestHeader('appdeviceid', appdeviceid);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    try {
        xhr.send(JSON.stringify(params));
        return JSON.parse(xhr.response);
    } catch (err) { }
}

export const get_actual_restaurants = () => (dispatch, getState) => {
    let state = getState();
    $api.get('/cities/' + state.authPage.currentCity + '/restaurants?isDaySushi=true&countDaySushi=' + state.authPage.citys[state.authPage.currentCity].setsInCart).then((response) => {
        //console.log(response);
        dispatch(set_restaurants(state.authPage.currentCity, response.data));
    });
}

export default authReducer;